import { Router, Route, BrowserRouter, Routes } from 'react-router-dom';
import './Reset.css';
// 문제없는데 에러가 남... 왤까
// import Main from './routes/Main';
import Project from './routes/Project';
import History from './routes/History';
import Schedule from './routes/Schedule';
import Main from './routes/Main';


import {BrowserView, MobileView} from 'react-device-detect';

function App() {
  return (
    //페이지 라우팅
    // https://stackoverflow.com/questions/69828516/you-cannot-render-a-router-inside-another-router-you-should-never-have-more
    <div>
      {/* 모바일 화면  : 개발자도구 모바일화면에서 새로고침 시 확인 가능 */}
      {/* <MobileView>
        <p>모바일 화면</p>
      </MobileView> */}

      {/* pc 브라우저 화면 */}
      {/* <BrowserView> */}
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/projects" element={<Project />}></Route>
          <Route path="/history" element={<History />}></Route>
          <Route path="/schedule" element={<Schedule />}></Route>
        </Routes>
      {/* </BrowserView> */}
    </div>
  );
}

export default App;