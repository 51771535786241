import "./NavigationBar.css";
import { Link } from "react-router-dom";


const NavigationBar = () => {
    return (
      <div className="nav">
        <div className="logo">
          <a href="/">
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/images/logo.svg`}
            ></img>
          </a>
        </div>
        <div className="gap"></div>
        <div className="areaLink">
          {/* 세부페이지 만들면 Link 태그로 바꾸기 */}
          {/* <Link className="link" to="/projects">
            프로젝트
          </Link> */}
          <div
            onClick={() =>
              alert("추후 업데이트될 페이지입니다. 조금만 기다려 주세요!")
            }
            className="link"
          >
            프로젝트
          </div>
        </div>
        <div className="areaLink">
          {/* <Link className="link" to="/history">
            연혁
          </Link> */}
          <div
            onClick={() =>
              alert("추후 업데이트될 페이지입니다. 조금만 기다려 주세요!")
            }
            className="link"
          >
            연혁
          </div>
        </div>
        <div className="areaLink">
          {/* <Link className="link" to="/schedule">
            활동일정
          </Link> */}
          <div
            onClick={() =>
              alert("추후 업데이트될 페이지입니다. 조금만 기다려 주세요!")
            }
            className="link"
          >
            활동일정
          </div>
        </div>
      </div>
    );
  }
  export default NavigationBar