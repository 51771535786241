import "./ProgramManu.css"




const ProgramManu = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘

    
    return (
      <div>
        {/* return() 안에 html 작성, 꼭 하나의 태그로 전채를 묶어야 에러가 나지 않음  */}

        <div className="partTitle">대표 프로그램</div>
        <div className="promenu_board">
          <div className="title">
            D-SUM
          </div>
          <div className="explanation">
            <p className="script">
              KUHAS에서는 정규 학기마다 전자및정보공학과 비교과활동 D-SUM에
              참여합니다. <br /> <br />
              KUHAS 멘토와 멘티들이 팀을 이루어 Python, C언어 등의 소프트웨어 언어
              기초를 학습합니다. <br /> <br />
              프로그래밍 언어를 처음 배우는 학우들도 부담없이 참여할 수
              있습니다.
            </p>
          </div>
          <div className="title">MT</div>
          <div className="explanation">
            <p className="script">
              KUHAS에서는 구성원간의 친목 도모를 위해 정규학기 초에 MT를
              진행합니다.
              <br /><br />
              여행을 통해 선후배 및 동기들과 함께 아름다운 청춘의 추억을
              쌓아봅시다.
            </p>
          </div>
          <div className="title">쿠하스의 밤</div>
          <div className="explanation">
            <p className="script">
              쿠하스의 밤은 학과 교수님, 졸업한 선후배 분들을 초빙하여 마련하는
              네트워킹의 장입니다. 
              <br/><br />
              학과 교수님들과 더욱 많은 교류를 원하는 학우,
              학교생활만으로는 접하기 어려운 현직자 분들과의 소통을 원하는
              학우라면 많은 참여 부탁드립니다.
            </p>
          </div>
          <div className="title">선택 커리큘럼</div>
          <div className="explanation">
            <p className="script">
              선택 커리큘럼은 KUHAS 운영진/멘토들이 진행하는 자체적인
              커리큘럼입니다. <br /><br />
              인공지능 / 데이터분석 / 웹개발 등 다양한 분야의 입문자들을
              대상으로합니다.
              <br /><br />
              관심있는 커리큘럼이 있다면 꼭 신청해 봅시다!
            </p>
          </div>
          <div className="title">자체 스터디</div>
          <div className="explanation">
            <p className="script">
              KUHAS 내부에서 진행하는 스터디는 구성원 누구나 언제든 제안이
              가능합니다.
              <br /><br />
              혼자서는 도전하기 힘든 분야도 뜻이 맞는 사람들과 함께 공부하며
              더욱 효과적으로 학습할 수 있기를 바랍니다.
            </p>
          </div>
        </div>
      </div>
    );
}
export default ProgramManu