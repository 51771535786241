import "./FandQ.css"
import { useState } from "react";

const FandQ = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘
 // inOn이라는 booleans 변수 선언한거라고 생각하십쇼
    // setisOn를 통해서만 isOn 값을 바꿀 수 있습니다
    
    return (
    <div>

        <div className="FAQground">
            <div className="Faq">FAQ</div>
          <div className="title_item" >
              <p> 모집기간은 언제인가요?</p>
          </div>
            <div className="answer_item">
                <p>저희 쿠하스는 <span className="vacationBold">여름방학</span>과 <span className="vacationBold">겨울방학</span>을 통해 모집하고 있습니다.</p>
            </div>
            <div className="title_item" >
                  <p> 지원조건은 어떻게 되나요?</p>
            </div>
            <div className="answer_item">
                <p>지원 자격은 따로 없습니다.
                    <br/>다만,
                    열정과 책임감이 있는 사람들을 바랍니다😊</p>
            </div>
            <div className="title_item" >
                  <p>커리큘럼은 어떻게 진행되나요?</p>
            </div>
            <div className="answer_item">
                <p>저희 쿠하스의 커리큘럼을 자세히 알고싶으시다면
                    <br /><span><a className="kuhasNotion" href="https://www.notion.so/K-U-H-A-S-3ff94268d9c74280b9840d56833ea762">KUHAS_노션페이지</a></span> 를 참고해주세요!</p>
            </div>
            <div className="title_item" >
                <div> 쿠하스에서 새로운 아이디어나 프로젝트를
                      제안할 수 있나요?</div>
            </div>
            <div className="answer_item">
                <p>네! 우리동아리는 회원들의 창의적인 아이디어와<br />
               프로젝트를 환영합니다.😆</p>
            </div>
        </div>

    </div>
    )
}
export default FandQ

