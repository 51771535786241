import "./Footer.css";

const Footer = () => {
    //함수 구현시 여기에다 작성

    
    return (
        <footer className="footer">
            {/*<div>footer부분</div>*/}
            <p className="footerText">
                <span className="footerHits"><a href="https://hits.seeyoufarm.com"><img src="https://hits.seeyoufarm.com/api/count/incr/badge.svg?url=http%3A%2F%2Fwww.kuhas.site&count_bg=%23AC0A52&title_bg=%23555555&icon=mixer.svg&icon_color=%23F2FF9B&title=KUHAS&edge_flat=false"/></a></span>
                <span className="footerTextIn" id='kuhasCopyright'>ⓒ 2023. KUHAS All Right Reserved.</span>
                {/*<span className="footerTextIn"><img className="footerImage" src="images/mail.svg"></img> E-Mail : koreauniv.kuhas@gmail.com</span>*/}
                {/*<span className="footerTextIn"><img className="footerImage" src="images/map.svg"></img> Location : 고려대학교 세종캠퍼스 과학기술1관 2층 </span>*/}

                <span className="footerTextIn"> E-Mail : koreauniv.kuhas@gmail.com</span>
                <span className="footerTextIn"> Location : 고려대학교 세종캠퍼스 과학기술1관 2층 </span>
            </p>
        </footer>

    )
}
export default Footer