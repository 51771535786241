import "./Introduce.css"
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';



const Introduce = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘

    useEffect(() => {
        AOS.init();
    }, [])

    return (

        <div>
            {/* return() 안에 html 작성, 꼭 하나의 태그로 전채를 묶어야 에러가 나지 않음  */}

            <div className="introduction_background">
                <div className="introduction_title_box">
                    <h2 className="introduction_title_text">
                        <span className="english_text">
                             KUHAS
                        </span>
                        <span>
                             &nbsp; 소개
                        </span>
                    </h2>
                </div>

                <ul className="introduction_list_box">
                    <li data-aos="fade-right" data-aos-easing="ease-in-out">
                            <div className="introduction_list_title_box">
                                <div className="introduction_list_title">
                                    <span className="introduction_list_title_num">
                                        01
                                    </span>
                                    <h3 className="introduction_list_title_text">
                                        <span>
                                            KUHAS 는 끊임없이 발전해 나가는 동아리 입니다.
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="introduction_explanation_text_box">
                                <p>
                                    KUHAS는 Hardware와 Software 기술의 무한한 가능성을 탐구하며, 끊임없이 성장해 나가는
                                    <br />
                                    고려대학교 세종캠퍼스 전자및정보공학과의 학술동아리입니다.
                                    <br />
                                    인공지능, 임베디드, Web페이지 개발 등 다양한 분야의 프로젝트와 창의적인 아이디어로
                                    <br />
                                    더 나은 미래를 위해 끊임없이 연구하고 노력하고 있습니다.
                                </p>
                            </div>
                    </li>

                    <li data-aos="fade-right" data-aos-easing="ease-in-out">
                            <div className="introduction_list_title_box">
                                <div className="introduction_list_title">
                                    <span className="introduction_list_title_num">
                                        02
                                    </span>
                                    <h3 className="introduction_list_title_text">
                                        <span>
                                            운영진과 활동인원이 함께 성장하는 동아리입니다.
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="introduction_explanation_text_box">
                                <p>
                                    KUHAS는 구성원들이 가진 열정과 재능을 인정하고, 서로를 존중하며 함께 성장하는 동아리입니다.
                                    <br />
                                    각자의 아이디어를 존중하고 협업을 통해 더 나은 결과물을 만들어내는 것을 중요시하며,
                                    <br />
                                    실패를 두려워하지 않고 새로운 도전에 도전하는 것이 우리 KUHAS의 비전입니다.
                                    <br />
                                    함께 KUHAS에서 하드웨어와 소프트웨어의 무한한 가능성을 탐험하고 성장해보세요.

                                </p>
                            </div>
                    </li>


                </ul>
            </div>

        </div>

    )

}
export default Introduce