import Footer from "../components/contents/Footer";
import NavigationBar from "../components/contents/NavigationBar";
import "./Project.css";

const Project = () => {
    return (
        <div>
            <NavigationBar></NavigationBar>
            <div className="pro_board">
                <span className="text">추후 업데이트될 페이지입니다. 조금만 기다려 주세요!</span>
            </div>
            <Footer></Footer>
        </div>
      );
  }
  export default Project