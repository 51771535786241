import "./MainDesign.css"
import { BrowserView, MobileView } from "react-device-detect";
import ThreeAnimation from "../contents/ThreeAnimation";

const MainDesign = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘




    // 메인화면을 퍼센트비율로 글씨를 만들어야겠다,,, 지금 px이라서 노트북 화면 사이즈 따라서도 ui가 다르네



    
    return (
      <div>
          <div className="board">
            {/* return() 안에 html 작성, 꼭 하나의 태그로 전채를 묶어야 에러가 나지 않음  */}
            <div className="textDiv">
              <div className="mainText">
                <span className="initialText">K</span>orea{" "}
              </div>
              <div className="mainText">
                <span className="initialText">U</span>niversity
              </div>
              <div className="mainText">
                <span className="initialText">H</span>ardware
              </div>
              <div className="mainText">
                <span className="initialText">A</span>nd
              </div>
              <div className="mainText">
                <span className="initialText">S</span>oftware
              </div>
            </div>

            <div>
              {/* <div className="ani">
              <ThreeAnimation />
            </div> */}
              <div className="teamText">
                <div>고려대학교 세종캠퍼스</div>
                <div>전자 및 정보공학과 학술 소모임</div>
                <div>KUHAS</div>
              </div>
            </div>
          </div>
      </div>
    );
}
export default MainDesign