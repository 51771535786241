import "./Main.css";
import Footer from "../components/contents/Footer";
import NavigationBar from "../components/contents/NavigationBar";
import ManagementCard from "../components/contents/ManagementCard";
import MainDesign from "../components/mainroutes/MainDesign";
import Introduce from "../components/mainroutes/Introduce";
import PictureCaroucel from "../components/mainroutes/PictureCaroucel";
import CurriculumManu from "../components/mainroutes/CurriculumManu";
import ProgramManu from "../components/mainroutes/ProgramManu";
import FandQ from "../components/mainroutes/FandQ";


import { useRef } from "react";

const Main = () => {
    //메인 페이지
// 헤더를 빼고 -> 어차피 상단 navbar foooter만 모든 routes에 추가
//layout 디렉토리는 삭제함
    
const inputForm = useRef();  //특정 DOM을 가리킬 때 사용하는 Hook함수, SecondDiv에 적용
const onMoveToForm = () => {
  inputForm.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
};


    return (
        <div >
            <NavigationBar></NavigationBar>

            <div className="mainBackground">
                <MainDesign></MainDesign>
            </div>


            {/* <div onClick={onMoveToForm} className="btnScroll"> 스크롤할 버튼</div> */}
            {/* <img onClick={onMoveToForm} className="btnScroll" src={`${process.env.PUBLIC_URL}/images/Arrow.png`}></img> */}

            <button onClick={onMoveToForm} className="btn"><img src={`${process.env.PUBLIC_URL}/images/arrow.svg`} className="btn_image"></img></button>
            <div ref={inputForm}></div>
            {/*<div className="test"></div>*/}


            <Introduce></Introduce>
            <PictureCaroucel></PictureCaroucel>
            <CurriculumManu></CurriculumManu>
            <ProgramManu></ProgramManu>
            <FandQ></FandQ>


            
            <Footer></Footer>
        </div>
    );
}
export default Main