import "./CurriculumManu.css"
const CurriculumManu = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘

    return (
    <div>
      <div className="Title"> Key Projects </div>
      <div className="Portfolio_ItemWrapper">
        <div className="Portfolio_Item">
            <a>
            <figure className="figure-container">
                <span className="part1">Web 개발</span>
                <img src="https://i.ibb.co/SyDcCnM/luca-bravo-XJXWbf-So2f0-unsplash-1.jpg" alt="web"/>
                <figcaption><br/><br/>
                             <span className="part2">Django는  Python로 만들어진 오픈소스 웹 애플리케이션 프레임워크(web application framework)입니다.<br/><br/>
                             다양한 기능들을 통해, 자신만의 홈페이지를 만들어 Career을 정리해보는 기술을 만들어 보는건 어때요? </span>
                </figcaption>
            </figure>
            </a>
        </div>
        <div className="Portfolio_Item">
            <a>
            <figure className="figure-container">
                <span className="part1">Linux</span>
                <img src="https://i.ibb.co/8gKjY5W/linux.png" alt="linux"/>
                <figcaption><br/><br/>
                             <span className="part2">Linux는 CLI 운영 체제(OS)입니다.
                             <br/><br/>프로그램을 실행하거나 폴더를 만들고, 파일을 복사하는 것 모두 마우스 없이 키보드만으로 글자를 입력해서 동작시켜야 합니다.
                             <br/><br/>OS는 기존의 익숙한 GUI 를 벗어나 CLI 를 사용해 리눅스를 학습함으로써, 새로운 OS에 이해도를 향상시킬 수 있습니다. </span>
                </figcaption>
            </figure>
            </a>
        </div>
        <div className="Portfolio_Item">
            <a>
            <figure className="figure-container">
                <span className="part1"> Arduino </span>
                <img src="https://i.ibb.co/FBgML9R/technology-g4c9c5b00c-640-1.jpg" alt="arduino"/>
                <figcaption><br/><br/>
                            <span className="part2"> Arduino란 단일 보드 마이크로컨트롤러로 완성된 보드(상품)와 관련 개발 도구 및 환경을 말합니다.
                            <br/><br/>아두이노 커리큘럼은 전자와 프로그래밍에 관심 있는 분들을 대상으로한 창의적인 프로그램입니다.
                            <br/><br/>아두이노 기초이론과 사용법을 배우고, 한번도 접해보지 못했더라도, 기초 지식과 익숙함을 제공하고자 합니다.</span>
                </figcaption>
            </figure>
            </a>
        </div>
        <div className="Portfolio_Item">
            <a>
            <figure className="figure-container">
                <span className="part1"> Data Analysis </span>
                <img src="https://i.ibb.co/98R1DZL/analysis-g86ec3e7c3-1280-1.jpg" alt="data analysis"/>
                <figcaption><br/><br/>
                             <span className="part2"> 지금은 빅데이터 시대입니다.<br/>
                             어느 직군을 선택하든 데이터 분석 역량은 매우 중요한 역량입니다.<br/><br/>
                             데이터 분석은 비즈니스 의사결정에 유용한 정보를 찾기 위한 데이터 정리, 변환, 모델링 과정으로 정의됩니다.<br/><br/>
                             멘토링과 간단한 프로젝트를 통해 데이터 분석에 대한 학습을 진행합니다. </span>
                </figcaption>
            </figure>
            </a>
        </div>
        <div className="Portfolio_Item">
            <a>

            <figure className="figure-container">
                <span className="part1"> AI</span>
                <img src="https://i.ibb.co/WV4SPTL/pexels-pavel-danilyuk-8294551-1.jpg" alt="AI"/>
                <figcaption><br/><br/>

                             <span className="part2">인공지능(AI)는 인간이 가진 지능을 갖춘 컴퓨터 시스템, 자연어 처리, 로봇 공학, 음성 번역, 문제 해결, 학습 등에 이용하는 컴퓨터 과학 기술 분야입니다.
                             <br/><br/>요즘 화두가 된 챗GPT, 알파고, 테슬라 등에도 인공지능이 들어가는데요.
                             <br/><br/>같이 한 번 인공지능의 세계로 빠져볼까요? </span>
                </figcaption>

            </figure>
            </a>
        </div>
        <div className="Portfolio_Item">
            <a>
            <figure className="figure-container">
                <span className="part1"> Autonomous Driving </span>
                <img src="https://i.ibb.co/6tKDjsM/selfdriving.jpg" alt="Autonomous Driving"/>
                <figcaption><br/><br/>
                            <span className="part2"> <br/> COMMING SOON </span>
                </figcaption>
            </figure>
            </a>
        </div>
      </div>
    </div>
    )
}
export default CurriculumManu