import "./PictureCaroucel.css"

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { Component } from "react";
import Slider from "react-slick";



const PictureCaroucel = () => {
    //함수 구현시 여기에다 작성
    // 1. 템플릿 언어 사용 불가.
    // 2. 아래 return() 안에 html 작성
    // css 입힐때는 className = "css네이밍" -> 이런식으로 입힘

    const settings = {
      arrows:false,
      dots: true,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,

    }


    
    return (
      <div className="slide_border">
        {/* return() 안에 html 작성, 꼭 하나의 태그로 전채를 묶어야 에러가 나지 않음  */}
        {/*활동사진 캐러셀 - 진영*/}
        <Slider {...settings}>
          <div>
            <div className="slide_title">종강 총회</div>
            <img
              className="slide_image"
              //   src="/slide_greeting1.svg"
              src="/images/ending.png"
              alt="종강 총회"
            ></img>
          </div>
          <div>
            <div className="slide_title">MT</div>
            <img
              className="slide_image"
              src="/images/MT.jpg"
              alt="종강 총회"
            ></img>
          </div>
          <div>
            <div className="slide_title">웹 개발 프로젝트</div>
            <img
              className="slide_image"
              src="/images/KuhasWeb.png"
              alt="웹 개발 프로젝트"
            ></img>
          </div>
          {/* <div>
            <div className="slide_title">하드웨어프로젝트</div>
            <img
              className="slide_image"
              src="/images/hardware_project.svg"
              alt="하드웨어프로젝트"
            ></img>
          </div> */}
          <div>
            <div className="slide_title">하드웨어 커리큘럼</div>
            <img
              className="slide_image"
              src="/images/hardware_c.jpg"
              alt="하드웨어 커리큘럼"
            ></img>
          </div>
          {/* <div>
            <div className="slide_title">대면식</div>
            <img
              className="slide_image"
              src="/slide_greeting1.svg"
              alt="대면식"
            ></img>
          </div> */}
        </Slider>
        {/* 디폴트 div태그    */}
      </div>
    );
}
export default PictureCaroucel
