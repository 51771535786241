import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* 상위 경로가 *이어야 이후 자식경로 렌더링이 됨. 아래 에러 참조 */}
        <Route path="*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// 경로 에러 
// You rendered descendant <Routes> (or called `useRoutes()`) at "/" (under <Route path="/">) but the parent route path has no trailing "*". This means if you navigate deeper, the parent won't match anymore and therefore the child routes will never render.

// Please change the parent <Route path="/"> to <Route path="*">.


//실행 에러
// git bash에서 실행할 때 경로!!!!!!!!가 잘못되면 실행시킬때마다 에러창이 뜨더라,,,(실행은 또 됨)
// git bash 상의 경로중에서 대소문자가 잘못되어있는 경우 실행은 되는데 에러창이 뜨므로 대소문자를 잘 지켜 주자.


//파일명 대소문자를 rename 할시,,, Already included file name... 에러가 발생...
//최근 경로에 맞게 경로 수정해주고 vscode 재실행
// -> 이후에도 안되면 git rm -r --cached . 코드 쳐서 캐시 삭제후 재실행